@use 'partials' as *;

.componentStyle {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.icon {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 1.2em;
  max-height: 1.2em;
  font-size: 1em;
}

.text {
  margin-left: 0.3em;
  font-weight: bold;
}
